<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        action-delete
        action-refresh
        @back="hRedirect('apps-affiliates-list')"
        @delete="helperDeleteItem(`/affiliates/${$route.params.affiliateid}`, null, 'apps-affiliates-list')"
        @save="updateItem"
        @refresh="getItem"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
        :title="currentItem.name"
    >
    </b-card-actions>
    <single-image v-if="loaded"
            :card-title="$t('Profile image')"
            :current-item="currentItem"
            :upload-url="avatarUploadUrl"
            :delete-url="avatarDeleteUrl"
            :image-url.sync="currentItem.avatar.url"
    >
    </single-image>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.stop.prevent="handleSubmit(updateItem)">
        <b-card>
          <b-row>
            <b-col md="6" xl="6" class="mb-1 mt-2">
              <validation-provider #default="validationContext" :name="$t('First Name')" rules="required">
                <b-form-group :label="$t('First Name')">
                  <b-form-input v-model="currentItem.firstname" id="firstname" :placeholder="$t('First Name')"/>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  <small v-if="validate && Object.keys(validation).includes('firstname')" class="text-danger">{{
                      $t(validation.firstname[0])
                    }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6" xl="6" class="mb-1 mt-2">
              <validation-provider #default="validationContext" :name="$t('Name')" rules="required">
                <b-form-group :label="$t('Name')">
                  <b-form-input v-model="currentItem.name" id="familytname" :placeholder="$t('Name')"/>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  <small v-if="validate && Object.keys(validation).includes('name')" class="text-danger">{{
                      $t(validation.name[0])
                    }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group :label="$t('Company')">
                <b-form-input v-model="currentItem.firm" id="firm" :placeholder="$t('Company')"/>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="6" class="mb-1">
              <b-form-group :label="$t('Position')">
                <b-form-input v-model="currentItem.position" id="position" placeholder="Position"/>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group :label="$t('Role')">
            <b-form-select v-model="currentItem.role" label="role" :options="roleOptions">
            </b-form-select>
          </b-form-group>

          <div>
            <b-form-group :label="$t('About me')">
              <b-form-textarea id="texterea-aboutme" md="12" rows="3" :placeholder="$t('About me')"
                               v-model="currentItem.aboutme"
              />
            </b-form-group>
          </div>


          <div>
            <b-form-group :label="$t('Birthday')" label-for="brithday">
              <b-input-group class="mb-1">
                <cleave v-model="currentItem.birthday_text" class="form-control" :raw="false" placeholder="DD.MM.YYYY"
                        :options="{
                  date: true,
                  delimiter: '.',
                  datePattern: ['d', 'm', 'Y'],
                }" v-on:blur="onBirthdayInput"
                />
                <b-input-group-append>
                  <b-form-datepicker button-variant="outline-primary" right size="sm" v-model="currentItem.birthday"
                                     right show-decade-nav button-only aria-controls="birthday" locale="de"
                                     :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                     @context="onBirthdayDatePickerChange"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card>
        <b-card :title="$t('Contact')">
          <b-row>
            <b-col md="6" xl="6">
              <validation-provider #default="validationContext" :name="$t('Email')" rules="required|email">
                <b-form-group :label="$t('Email')">
                  <b-form-input v-model="currentItem.email" id="email" placeholder="Email"/>
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  <small v-if="validate && Object.keys(validation).includes('email')" class="text-danger">{{
                      $t(validation.email[0])
                    }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="6">
              <b-form-group :label="$t('Phone')">
                <b-form-input v-model="currentItem.phone" id="phone" :placeholder="$t('Phone')"/>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card :title="$t('Login')">
          <b-row>
            <!-- password -->
            <b-col cols="6">
              <b-form-group :label="$t('Password')">
                <validation-provider #default="{ errors }" :label="$t('Password')" :name="$t('Password')" vid="Password"
                                     rules="min:8|password" class="mt-3"
                >
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input v-model="currentItem.password" :state="errors.length > 0 ? false : null"
                                  class="form-control-merge" :type="passwordFieldType" :placeholder="$t('Password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                                    @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                  <small v-if="validate && Object.keys(validation).includes('password')" class="text-danger">{{
                      $t(validation.password[0])
                    }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- confirm password -->
            <b-col cols="6">
              <b-form-group :label="$t('Confirm Password')">
                <validation-provider #default="{ errors }" :name="$t('Confirm Password')" rules="confirmed:Password">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input v-model="currentItem.password_confirmation" :state="errors.length > 0 ? false : null"
                                  class="form-control-merge" :type="passwordconfirmFieldType"
                                  :placeholder="$t('Confirm Password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordconfirmToggleIcon"
                                    @click="togglePasswordConfirmVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                  <small v-if="validate && Object.keys(validation).includes('password_confirmation')"
                         class="text-danger"
                  >{{ $t(validation.password_confirmation[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-button variant="success" type="submit">
            {{ $t('Save') }}
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>

  </component>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BCardText,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormFile,
  BAvatar,
  BMedia,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required, alphaNum, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import SingleImage from '@/views/components/SingleImage'
import BCardActions from '@core/components/b-card-actions/BCardActions'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    vSelect,
    BFormFile,
    BAvatar,
    BMedia,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    Cleave,
    SingleImage,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BCardActions,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordconfirmToggleIcon() {
      return this.passwordconfirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  data() {
    return {
      firstname: '',
      currentItem: {
        avatar: {
          url: null
        },
        id: '',
        name: '',
        email: '',
        firstname: '',
        familyname: '',
        firm: '',
        position: '',
        aboutme: '',
        slogan: '',
        birthday: '',
        birthday_text: '',
        references: '',
        testimonials: '',
        startdate: '',
        enddate: '',
        phone: '',
        invoice_street: '',
        invoice_postal: '',
        invoice_city: '',
        password_confirmation: '',
        password: '',
        role: '',
      },

      loaded: true,
      validation: {},
      validate: false,
      roleOptions: [
        {
          value: 'admin',
          text: this.$i18n.t('Admin'),
        },
        {
          value: 'user',
          text: this.$i18n.t('User'),
        },
      ],
      avatarUploadUrl: `/affiliates/avatarupload/${this.$route.params.affiliateid}`,
      avatarDeleteUrl: `/affiliates/avatarremove/${this.$route.params.affiliateid}`,
    }
  },

  methods: {
    getItem() {
      this.helperGetItem(
          `/affiliates/${this.$route.params.affiliateid}/edit`, () => {
            if (this.currentItem.avatar) {
              this.currentItem.avatar = {url: null}
            }
          }
      )
    },
    updateItem() {
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            this.validate = false
            if (success) {
              this.helperUpdateItem(
                  `/affiliates/${this.$route.params.affiliateid}`,
                  this.currentItem
              )
            } else {
              this.loaded = false
              setTimeout(() => {
                this.loaded = true
              })
            }
          })

    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },

    onBirthdayDatePickerChange(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
      this.currentItem.birthday_text = moment(ctx.selectedYMD, 'YYYY-MM-DD')
          .format('DD.MM.YYYY')
    },
    onBirthdayInput() {
      let date = moment(this.currentItem.birthday_text, 'DD.MM.YYYY')

      if (date.isValid()) {
        this.currentItem.birthday = date.format('YYYY-MM-DD')
      } else {
        this.currentItem.birthday = null
        this.currentItem.birthday_text = ''
      }
    },

  },
  beforeMount() {
    this.addBreadcrumbDataFromRouteParams(this.$route.params)
  },
  mounted() {
    this.getItem()
  },
}
</script>
